export const theme = {
  fontFamily: `font-family: "Montserrat", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif`,
  color: {
    black: '#292929',
    offwhite: '#ebecf0',
    grey: '#4c5267',
    darkhorse: '#212736',
    orange: '#ffa21f',
    lightPink: '#ff80cb',
    pink: '#b5267b',
    darkPink: '#b5267b',
    purple: '#632695',
    rhubarb: '#ff1f71',
    get pinkToPurple() {
      return `linear-gradient(to right, ${this.pink}, ${this.purple})`;
    },
    get pinkToOrange() {
      return `linear-gradient(to right, ${this.pink}, ${this.orange})`;
    },
    get darkhorseToOrange() {
      return `linear-gradient(to right, ${this.darkhorse}, ${this.orange})`;
    },
  },
  maxWidth: '1140px',
};
