import styled from 'styled-components';

const BackgroundBlock = styled.div`
  background: ${props =>
    props.background ? props.background : props.theme.color.darkhorse};
  padding: ${props => props.padding};
  padding-top: ${props => props.paddingTop};
  padding-bottom: ${props => props.paddingBottom};
  padding-left: ${props => props.paddingLeft};
  padding-right: ${props => props.paddingRight};
`;

export { BackgroundBlock };
