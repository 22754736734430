import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { theme } from '../styles/themes';
import Logo from '../../static/images/gatsby-icon.png';

const NavElementStyle = {
  textDecoration: `none`,
  fontSize: `16px`,
};

const FooterStyle = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 13rem;
  padding: 0 4vw;
  background: ${props => props.theme.color.black};
  color: white;
`;

const Inner = styled.div`
  margin: 0 auto;
  max-width: 1140px;
  padding: 1.45rem 0;
  display: flex;
  justify-content: space-between;
`;

const Branding = styled.div`
  display: grid;
  margin-right: 1rem;
  grid-template-rows: repeat(1fr);
  align-items: center;
  vertical-align: center;
`;

const BrandingText = styled.span`
  font-size: 80%;
  @media only screen and (max-device-width: 736px) {
    font-size: 70%;
  }
`;

const NavBar = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 10rem;
  border: 2px solid;
  border-radius: 2px;
  border-color: ${props => props.theme.color.orange};
  padding: 8px 4rem;
  a {
    color: white;
  }
  h4 {
    color: ${props => props.theme.color.orange};
    padding-top: 12px;
  }
`;

const ActiveStyle = {
  color: theme.color.rhubarb,
};

const linkList = [
  {
    address: '/about',
    content: 'About',
  },
  {
    address: '/contact',
    content: 'Contact',
  },
];

const Links = () => (
  <>
    {linkList.map(el => (
      <Link
        to={el.address}
        style={NavElementStyle}
        activeStyle={ActiveStyle}
        key={el.content}
      >
        {el.content}
      </Link>
    ))}
  </>
);

const Footer = ({ siteDescription }) => (
  <FooterStyle>
    <Inner>
      <Branding>
        <Link to="/" style={NavElementStyle} activeStyle={ActiveStyle}>
          <img
            src={Logo}
            alt="Logo"
            style={{
              maxWidth: `100px`,
              margin: `auto 0`,
            }}
          />
        </Link>
        <BrandingText>{siteDescription}</BrandingText>
        <BrandingText>© {new Date().getFullYear()} Turnt Tech LLC</BrandingText>
      </Branding>
      <NavBar aria-label="Bottom Navigation" right>
        <h4>Links</h4>
        <Links />
      </NavBar>
    </Inner>
  </FooterStyle>
);

Footer.propTypes = {
  siteDescription: PropTypes.string,
};

export default Footer;
