import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { slide as Menu } from 'react-burger-menu';
import { MobileContext } from './context/exports';
import Logo from '../../static/images/gatsby-icon.png';
import { theme } from '../styles/themes';
import '../styles/burger-bar.css';

const NavElementStyle = {
  textDecoration: `none`,
  fontSize: `1rem`,
};

const HeaderStyle = styled.header`
  position: sticky;
  background: white;
  top: 0;
  padding: 0 4vw 0;
  z-index: 1;
  border: 1px solid hsla(0, 0%, 0%, 0.1);
`;

const Inner = styled.div`
  margin: 0 auto;
  max-width: 1140px;
  padding: 1.45rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const NavBar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 450px;
  a {
    color: ${props => props.theme.color.darkhorse};
  }
`;

const Button = styled.button`
  width: 9em;
  background: ${props => props.theme.color.offwhite};
  border-color: ${props => props.theme.color.darkhorse};
  border-radius: 5px;
  border-width: 1px;
  color: ${props => props.theme.color.darkhorse};
  padding: 0 20px;
  transition: 0.25s;

  :hover {
    box-shadow: inset -4.5em 0 0 0 ${props => props.theme.color.darkhorse},
      inset 4.5em 0 0 0 ${props => props.theme.color.darkhorse};
    color: #fff;
  }
`;

const ActiveStyle = {
  color: theme.color.rhubarb,
};

const linkList = [
  {
    address: '/',
    content: 'Home',
  },
  {
    address: '/about',
    content: 'About',
  },
  {
    address: '/contact',
    content: 'Contact',
  },
];

const Links = () => (
  <>
    {linkList.map(el => (
      <Link
        to={el.address}
        style={NavElementStyle}
        activeStyle={ActiveStyle}
        key={el.content}
      >
        {el.content}
      </Link>
    ))}
  </>
);

const Header = () => {
  const isMobile = useContext(MobileContext);
  const MenuWrapper = isMobile ? Menu : NavBar;
  return (
    <HeaderStyle>
      <Inner>
        <Link to="/" style={NavElementStyle} activeStyle={ActiveStyle}>
          <img
            src={Logo}
            alt="Company logo"
            style={{
              maxWidth: `100px`,
              margin: `auto 0`,
            }}
          />
        </Link>
        <MenuWrapper aria-label="Main Navigation" right>
          <Links />
          <Button type="button">Get Started</Button>
        </MenuWrapper>
      </Inner>
    </HeaderStyle>
  );
};

export default Header;
