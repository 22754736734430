import React from 'react';
import { Link } from 'gatsby';
import { TextBlock } from './block-components/exports';

const ConstructionEmoji = () => (
  <span role="img" aria-label="Construction Sign">
    🚧
  </span>
);

const UnderConstruction = () => (
  <TextBlock maxWidth="600px" padding="4rem 0" textAlign="center">
    <h2>
      <ConstructionEmoji />
      Under Construction
      <ConstructionEmoji />
    </h2>
    <p>Check back soon!</p>
    <Link to="/" activeStyle={{ textAlign: 'center' }}>
      Go back to the homepage
    </Link>
  </TextBlock>
);

export { UnderConstruction };
