import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const handleGrid = imagePosition => {
  switch (imagePosition) {
    case 'left':
      return `grid-column: 1;\ngrid-row: 1;`;
    case 'right':
      return `grid-column: 2;\ngrid-row: 1;`;
    default:
      return `grid-column: 2;\ngrid-row: 1;`;
  }
};

const handlePortraitGrid = imagePosition => {
  switch (imagePosition) {
    case 'left':
      return `grid-column: 1;\ngrid-row: 1;`;
    case 'right':
      return `grid-column: 1;\ngrid-row: 1;`;
    default:
      return `grid-column: 1;\ngrid-row: 1;`;
  }
};

const ContentContainer = styled.div`
  padding-top: 4vw;
`;

const Inner = styled.div`
  padding-bottom: ${props => props.paddingBottom};
  padding-top: ${props => props.paddingTop};
  max-width: ${props => props.theme.maxWidth};
  margin-left: auto;
  margin-right: auto;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 5%;
  justify-items: center;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 668px) {
    div:nth-child(1) {
      ${({ imagePosition }) => handleGrid(imagePosition)}
    }
  }
  @media screen and (max-width: 667px) {
    div:nth-child(1) {
      ${({ imagePosition }) => handlePortraitGrid(imagePosition)}
    }
  }
`;

const GridBlock = props => {
  const { imagePosition, paddingBottom, paddingTop, children } = props;
  return (
    <ContentContainer>
      <Inner paddingBottom={paddingBottom} paddingTop={paddingTop}>
        <Grid imagePosition={imagePosition}>{children}</Grid>
      </Inner>
    </ContentContainer>
  );
};

GridBlock.propTypes = {
  children: PropTypes.array,
  imagePosition: PropTypes.string,
  paddingTop: PropTypes.string,
  paddingBottom: PropTypes.string,
};

GridBlock.defaultProps = {
  paddingTop: '4.16667rem',
  paddingBottom: '3.33333rem',
};

export { GridBlock };
