import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { MobileContext } from '../context/exports';

const StyledTextBlock = styled.div`
  margin: 0 auto;
  min-width: 300px;
  max-width: ${props => props.maxWidth};
  text-align: ${props => props.textAlign};
  color: ${props => props.color};
  padding: ${props => props.padding};
  h2 {
    line-height: 1.5;
    font-weight: bold;
    color: ${props => props.h2Color};
  }
  p {
    line-height: 1.66667;
    font-size: 90%;
    color: ${props => props.pColor};
  }
  ul {
    padding: 12px 0;
  }
  li {
    padding: 8px 0;
  }
`;

const TextBlock = ({
  children,
  color,
  maxWidth,
  textAlign,
  pColor,
  h2Color,
  padding,
}) => {
  const isMobile = useContext(MobileContext);
  return (
    <StyledTextBlock
      isMobile={isMobile}
      color={color}
      maxWidth={maxWidth}
      textAlign={textAlign}
      pColor={pColor}
      h2Color={h2Color}
      padding={padding}
    >
      {children}
    </StyledTextBlock>
  );
};

TextBlock.propTypes = {
  color: PropTypes.string,
  maxWidth: PropTypes.string,
  textAlign: PropTypes.string,
  pColor: PropTypes.string,
  h2Color: PropTypes.string,
  padding: PropTypes.string,
  children: PropTypes.array,
};

TextBlock.defaultProps = {
  color: `${props => props.theme.color.black}`,
  maxWidth: '555px',
  textAlign: `${props => (props.isMobile ? 'center' : 'left')}`,
  padding: '0 1rem',
};

export { TextBlock };
