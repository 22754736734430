import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { UnderConstruction } from '../components/under-construction';

const Contact = () => (
  <Layout>
    <SEO title="Contact Us" />
    <UnderConstruction />
  </Layout>
);

export default Contact;
