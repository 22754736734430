/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { useMediaQuery } from 'react-responsive';
import { MobileContext } from './context/exports';

import Header from './header';
import Footer from './footer';
import { theme } from '../styles/themes';
import '../styles/layout.css';

const Container = styled.div`
  position: relative;
  min-height: 100vh;
`;

const Content = styled.div`
  padding-bottom: 13rem;
`;

const Layout = ({ children }) => {
  const isMobile = useMediaQuery({
    query: '(max-device-width: 1024px)',
  });
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          description
        }
      }
    }
  `);

  return (
    <MobileContext.Provider value={isMobile}>
      <ThemeProvider theme={theme}>
        <Container>
          <Header />
          <Content>
            <main>{children}</main>
          </Content>
          <Footer siteDescription={data.site.siteMetadata.description} />
        </Container>
      </ThemeProvider>
    </MobileContext.Provider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
